.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


:root {
  --primary-color: #5f2972;
  --complementary-color: #a4b357;
  --analogous-color: #4a2059;
  --complementary-color: #a4b357;
  --monocromatic-color: #7f5990;
  --triadic-color: #29725f;
  --triadic2-color: #725f29;
  --font-size: 16px;
}

.colismi-bg{
  background-color: var(--primary-color) !important;
}

.colismi-button{
  background-color: var(--primary-color) !important;
  color: #ffff !important;
  padding: 5px;
  border-radius: 5px;
  margin: auto;
  justify-content: center;
}

.colismi-button-cm{
  background-color: var(--complementary-color) !important;
  color: #ffff !important;
  padding: 5px;
  border-radius: 5px;
  margin: auto;
  justify-content: center;
}

.colismi-button-nobg{
  color: #ffff !important;
  padding: 5px;
  border-radius: 5px;
}

.colismi-button-effect:hover{
  transform: scale(1.05);
}

.colismi-button-nobg:hover{
  transform: scale(1.05);
}
.colismi-button-cm:hover{
  transform: scale(1.05);
}
.colismi-button:disabled{
 /* background-color: rgb(179, 179, 179) !important;*/
}
.colismi-button:hover{
  background-color: var(--analogous-color) !important;
  transform: scale(1.05);
}

.colismi-icon{
  color: var(--monocromatic-color);
}

.colismi-icon:hover{
  transform: scale(1.05);
}

#menu-avatar{
  width: 40px;
  height: 40px;
  
}

#menu-avatar:hover{
  cursor: pointer;
}

.navbar-background{
  background: linear-gradient(180deg, rgba(38, 15, 47, 0.90) 39.12%, rgba(95, 41, 114, 0.36) 130.93%);
  backdrop-filter: blur(7.5px);
}

.input-colismi{
  border-radius: 5px;
  font: 400 14px Poppins, sans-serif;
  color: #b490c0;

}
.input-wrapper {
  
  justify-content: space-between;
  border-radius: 5px;
  background-color: #f6f1f8;
  margin-top: 8px;
  gap: 10px;
  padding: 10px 8px;
}

.bg-services{
  background-color: #F6F1F8;;
}

.titre-home{
  color: #7F5190;
}

.fill-colismi{
  fill: #B490C0 0.05;
}