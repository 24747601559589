.shipment-card {
    padding: 0 0 16px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
    background-color: #fff;
    display: flex;
    max-width: 530px;
    flex-direction: column;
  }
  
  .shipment-header {
    background-color: #f6f1f8;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
  }
  
  .shipment-details {
    justify-content: space-between;
    display: flex;
    gap: 20px;
  }
  
  .location-info {
    align-self: start;
    display: flex;
    flex-direction: column;
  }
  
  .location-wrapper {
    display: flex;
    gap: 8px;
    font-size: 16px;
    color: #7f5190;
    font-weight: 500;
    white-space: nowrap;
  }
  
  .location-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
  }
  
  .location-name {
    font-family: Lexend, sans-serif;
  }
  
  .date {
    color: #b490c0;
    align-self: center;
    margin-top: 8px;
    font: 400 12px Lexend, sans-serif;
  }
  
  .arrow-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 32px;
    margin: auto 0;
  }
  
  .status-badge-actif {
    justify-content: center;
    border-radius: 30px;
    background-color: #519062;
    color: #fff;
    white-space: nowrap;
    margin: auto 0;
    padding: 8px 32px;
    font: 400 14px Lexend, sans-serif;
  }

  .status-badge-annuler {
    justify-content: center;
    border-radius: 30px;
    background-color: #dadcda;
    color: #fff;
    white-space: nowrap;
    margin: auto 0;
    padding: 8px 32px;
    font: 400 14px Lexend, sans-serif;
  }
  
  .shipment-info {
    justify-content: space-between;
    display: flex;
    margin-top: 16px;
    width: 100%;
    gap: 8px;
    font-size: 12px;
    font-weight: 400;
    padding: 0 16px;
  }
  
  .info-list {
    display: flex;
    flex-direction: column;
    color: #b490c0;
  }
  
  .info-item {
    display: flex;
    gap: 8px;
    margin-top: 8px;
  }
  
  .info-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
  }
  
  .info-text {
    font-family: Lexend, sans-serif;
    margin: auto 0;
  }
  
  .shipment-number {
    color: #b490c0;
    font-family: Lexend, sans-serif;
    align-self: start;
  }
  
  .shipment-actions {
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    padding: 0 16px;
  }
  
  .action-divider {
    border-radius: 20px;
    background-color: #f6f1f8;
    height: 2px;
  }
  
  .action-buttons {
    justify-content: center;
    display: flex;
    margin-top: 16px;
    gap: 16px;
  }
  
  .action-button {
    justify-content: center;
    border-radius: 15px;
    display: flex;
    gap: 10px;
    flex: 1;
    padding: 12px 16px;
    font-family: Lexend, sans-serif;
  }
  
  .cancel-button {
    background-color: #f6f1f8;
    color: #7f5190;
  }
  
  .manage-button {
    background-color: #7f5190;
    color: #fff;
  }
  
  .action-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 16px;
    align-self: start;
  }
  
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  @media (max-width: 991px) {
    .shipment-header,
    .shipment-info,
    .shipment-actions,
    .action-divider {
      max-width: 100%;
    }
  
    .shipment-details,
    .action-buttons {
      flex-wrap: wrap;
    }
  
    .location-wrapper,
    .status-badge {
      white-space: initial;
    }
  
    .status-badge {
      padding: 8px 20px;
    }
  
    .action-buttons {
      white-space: initial;
    }
  
    .action-button {
      padding: 12px 20px;
    }
  }