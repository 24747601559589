.commentaire-card {
    
    align-self: stretch;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
    background-color: #fff;
    display: flex;
    max-width: 825px;
    flex-direction: column;
    margin-top: 10px;
  }
  
  .commentaire-header {
    background-color: #f6f1f8;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .commentaire-info {
    justify-content: space-between;
    display: flex;
    width: 100%;
    gap: 20px;
  }
  
  .com-user-details {
    justify-content: center;
    display: flex;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
  }
  
  .com-user-avatar {
    aspect-ratio: 1;
    object-fit: cover;
    width: 44px;
  }
  
  .com-user-name-rating {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 4px 0;
  }
  
  .com-user-name {
    color: #7f5190;
    font-family: Lexend, sans-serif;
  }
  
  .rating-container {
    display: flex;
    gap: 4px;
    white-space: nowrap;
  }
  
  .rating-value {
    color: #7f5190;
    font-family: Lexend, sans-serif;
  }
  
  .rating-star {
    aspect-ratio: 1;
    object-fit: contain;
    width: 14px;
    margin: auto 0;
  }
  
  .rating-count {
    color: #b490c0;
    font-family: Lexend, sans-serif;
  }
  
  .commentaire-date {
    display: flex;
    gap: 8px;
    font-size: 12px;
    color: #b490c0;
    font-weight: 400;
    margin: auto 0;
  }
  
  .date-icon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 16px;
  }
  
  .date-text {
    font-family: Lexend, sans-serif;
  }
  
  .commentaire-content {
    /*align-self: center;*/
    display: flex;
    margin-top: 16px;
    gap: 8px;
    font-size: 12px;
    color: #b490c0;
    font-weight: 400;
    padding: 0 20px;
  }
  
  .quote-icon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 20px;
    align-self: start;
  }
  
  .commentaire-text {
    font-family: Lexend, sans-serif;
    flex: 1;
  }
  
  @media (max-width: 991px) {
    .commentaire-header {
      max-width: 100%;
    }
  
    .commentaire-info {
      max-width: 100%;
      flex-wrap: wrap;
    }
  
    .rating-container {
      white-space: initial;
    }
  
    .commentaire-content {
      flex-wrap: wrap;
    }
  
    .commentaire-text {
      max-width: 100%;
    }
  }