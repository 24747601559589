




.type-transport{
    width: 60px;
    height: 60px;
    justify-content: center;
}

.top-display{
    height: 30%;
}

@media (max-width: 767px) {
    .travel-details{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        justify-content: center;
        margin: auto;
    }
    .nom-ville{
        font-size: 50px;
        height: auto;
    }
    .travel-detail-header{
        display: grid;
        width: 80%;
        height: 200px;
        justify-content: center;
        grid-template-columns: 100% ;
        text-align: center;
        margin: auto;
    }

    .details-trajet{
        display: grid;
        grid-template-columns: 100%;
        gap: 10px;
        width: 100%;
        margin: auto;
    
      }
    

  .publication-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 296px;
    padding: 20px 20px;
    border-radius: 10px;
    background-color: var(--3-Secondair, #f6f1f8);
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

@media (min-width: 768px) {
    .travel-details{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        height: auto;
        width: 100%;
        justify-content: center;
    }

    .nom-ville{
        font-size: 30px;
        height: auto;
    }
    .travel-detail-header{
        display: grid;
        width: 80%;
        height: 100px;
        justify-content: center;
        grid-template-columns: 3fr 1fr 3fr ;
        text-align: center;
        margin: auto;
        padding-top: 10px ;
    }
    .details-trajet{
        display: grid;
        grid-template-columns: 2fr 7fr;
        gap: 10px;
        width: 90%;
        max-width: 1024px;
        justify-content: center;
        margin: auto;
    
      }

  .publication-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 296px;
    padding: 32px 58px;
    border-radius: 10px;
    background-color: var(--3-Secondair, #f6f1f8);
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

@media (width: 2440px) {

    .details-trajet{
        display: grid;
        grid-template-columns: 2fr 7fr;
        gap: 10px;
        width: 70%;
        justify-content: center;
        margin: auto;
    
      }
}



@media (width: 1024px) {
    .travel-details{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        min-height: 150px;
        width: 80%;
        justify-content: center;
    }
    
}


.travel-details-item{
    background-color: rgb(248, 248, 249);
    margin-right: 5px;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
    display: block;
    padding: 15px;
    z-index: 2;
}

.client-input{

    width: 70%;
    justify-content: center;
    margin: auto;
}


.travel-commentaire{
    margin-top: 20px;
    position: relative;
    display: block;
    width: 70%;
    margin: auto;
    clear: both;
    z-index: 2;
}

.details-top{
    display: flex;
    width: 90%;
    max-width: 1024px;
    margin: auto;
    justify-content: center;
    margin-top: 20px;
}

.user-profile-block{
}
.type-transport{
    justify-content: center;
    margin: auto;
}


.event-card {
    align-items: start;
    border-radius: 30px;
    box-shadow: -2px -2px 0px 0px rgba(0, 0, 0, 0.2) inset, 2px 2px 0px 0px rgba(255, 255, 255, 0.1) inset, 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    background-color: rgba(127, 81, 144, 0.1);
    display: flex;
    max-width: 963px;
    gap: 20px;
    color: #fff;
    justify-content: space-between;
    padding: 16px 32px;
  }
  .event-details {
    display: flex;
    flex-direction: column;
  }
  .event-title {
    font: 600 32px Lexend, sans-serif;
  }
  .event-date-wrapper {
    align-self: center;
    display: flex;
    margin-top: 12px;
    gap: 10px;
    font: 500 20px Lexend, sans-serif;
  }
  .icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
    align-self: start;
  }
  .event-image {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 80px;
    align-self: stretch;
  }
  @media (max-width: 991px) {
    .event-card {
      flex-wrap: wrap;
      padding: 0 20px;
    }
  }



  
  .user-avatar {
    align-self: center;
    width: 32px;
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
  }
  
  .publication-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    padding: 0 10px;
  }
  
  .user-name-time {
    color: var(--1-Principal, #7f5190);
    font: 500 16px Lexend, sans-serif;
    align-self: center;
  }
  
  .publication-type {
    align-self: center;
    margin-top: 8px;
    color: var(--2-Textes, #b490c0);
    font: 400 14px Lexend, sans-serif;
  }

  .details-trajet-post{

    padding-top: 40px;
  }


  /*********Détails trajet************/
