


.button-send-message{
    color: var(--primary-color);
}

.chat-msg-container{
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
}

.chat-users-container{
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
}

.enligne{
    color: green;
}

.liste-user{
    user-select: none;
}

.liste-user:hover{
    cursor: pointer;
}

.liste-user-active{
    background-color: #7f5990;
}

.message-item{
    width: 70%;
    padding-left: 70px ;

}

.message-item-text-right{
    min-width: 200px;
    padding: 10px;
    float: right;
    background-color: #f6f1f8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);

}

.message-item-text-left{
    min-width: 200px;
    padding: 10px;
    float: left;
    background-color: #b490c0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);

}


.image-no-res{
    width: 40%;
    height: 40%;
    justify-content: center;
    text-align: center;
    margin: auto;
}
@media (min-width: 1440px) {
   
    .chat-zone{
        width: 70%;
        margin: auto;
        justify-content: center;
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .chat-container{
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 5fr;
        gap: 10px;
        margin-bottom: 20px;
    }

    .chat-zone{
        width: 80%;
        margin: auto;
        justify-content: center;
        margin-top: 20px;
    }
    #liste-messages{
        overflow-y: auto;
        margin-bottom: 10px;
    }
}

@media (max-width: 768px) {
    .chat-container{
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
    }

    .chat-zone{
        width: 90%;
        margin: auto;
        justify-content: center;
        margin-top: 20px;
    }

    .chat-msg-container{
        width: 100%;
    }

    #liste-messages{
        max-height: 300px;
        overflow-y: auto;
        margin-bottom: 10px;
    }
}

#header-messages{
    max-height: 150px;
    width: 100%;
   /* background-color: #7f5990;
    color: white;*/
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
}



.last-message{
    margin-left: 20px;
    font-size: 12px;
}

.last-message-div{
    display: flex;
    gap: 20px;
}

#circle {
    width: 10px;
    height: 10px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    background: #7f5990;
  }

  .liste-user-div:hover{
    background-color: #f6f1f8;
  }


/* ben test */

/* General button styles */
.button {
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.button-yes {
    background-color: #4CAF50; /* Green */
    color: white;
}

.button-no {
    background-color: #f44336; /* Red */
    color: white;
}

.button-reconfigure {
    background-color: #5f2972; /* Blue */
    color: white;
}

/* Hover effects */
.button-yes:hover {
    background-color: #45a049; /* Darker green */
}

.button-no:hover {
    background-color: #e53935; /* Darker red */
}

.button-reconfigure:hover {
    background-color: #5f2972; /* Darker blue */
}

/* Focus styles for accessibility */
.button:focus {
    outline: 2px solid #000;
    outline-offset: 2px;
}

/* Container styles for confirmation and reconfigure messages */
.message-container {
    padding: 20px;
    border-radius: 10px;
    background-color: #b490c0;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
    text-align: center;
    margin: 20px;
    color: #ffff;
}

.message-text {
    font-size: 18px;
    margin-bottom: 20px;
    color: #333;
}
