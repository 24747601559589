.parent-review-card{
  margin-top: 10px;
  margin-bottom: 10px;
}


.review-card {
    padding: 16px 0;
    align-self: stretch;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
    background-color: #fff;
    display: flex;
    max-width: 1000px;
    flex-direction: column;
  }
  
  .review-header {
    background-color: #f6f1f8;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
  }
  
  .reviewer-info {
    justify-content: space-between;
    display: flex;
    width: 100%;
    gap: 20px;
  }
  
  .reviewer-profile {
    justify-content: center;
    display: flex;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
  }
  
  .profile-image {
    aspect-ratio: 1;
    object-fit: cover;
    width: 44px;
  }
  
  .name-rating {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 4px 0;
  }
  
  .reviewer-name {
    color: #7f5190;
    font-family: Lexend, sans-serif;
  }
  
  .rating-container {
    display: flex;
    gap: 4px;
    white-space: nowrap;
  }
  
  .rating-value {
    color: #7f5190;
    font-family: Lexend, sans-serif;
  }
  
  .star-icon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 14px;
    margin: auto 0;
  }
  
  .review-count {
    color: #b490c0;
    font-family: Lexend, sans-serif;
  }
  
  .review-date {
    display: flex;
    gap: 8px;
    font-size: 12px;
    color: #b490c0;
    font-weight: 400;
    margin: auto 0;
  }
  
  .clock-icon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 16px;
  }
  
  .date-text {
    font-family: Lexend, sans-serif;
  }
  
  .review-details {
    justify-content: space-between;
    display: flex;
    margin-top: 16px;
    gap: 8px;
    font-size: 12px;
    font-weight: 400;
    padding: 0 16px;
  }
  
  .weight-info {
    align-self: start;
    display: flex;
    gap: 8px;
    color: #b490c0;
  }
  
  .weight-icon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 20px;
  }
  
  .weight-text {
    font-family: Lexend, sans-serif;
    margin: auto 0;
  }
  
  .review-content {
    justify-content: flex-end;
    display: flex;
    gap: 8px;
    color: #b490c0;
    padding: 0 8px;
  }
  
  .quote-icon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 20px;
    align-self: start;
  }
  
  .review-text {
    font-family: Lexend, sans-serif;
  }
  
  .action-section {
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
    font-size: 14px;
    color: #7f5190;
    font-weight: 400;
    white-space: nowrap;
    padding: 0 16px;
  }
  
  .divider {
    border-radius: 20px;
    background-color: #f6f1f8;
    height: 2px;
  }
  
  .action-buttons {
    justify-content: center;
    display: flex;
    margin-top: 16px;
    gap: 16px;
  }
  
  .action-button {
    justify-content: center;
    border-radius: 15px;
    display: flex;
    gap: 10px;
    flex: 1;
    padding: 12px 16px;
    font-family: Lexend, sans-serif;
  }
  
  .chat-button {
    background-color: #f6f1f8;
  }
  
  .reject-button {
    background-color: #f6f1f8;
  }
  
  .accept-button {
    background-color: #7f5190;
    color: #fff;
  }

  .valid-button {
    background-color: #21b042;
    color: #fff;
  }
  
  .action-icon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 16px;
    align-self: start;
  }
  
  @media (max-width: 991px) {
    .review-header,
    .reviewer-info,
    .review-details,
    .action-section,
    .divider,
    .action-buttons {
      max-width: 100%;
    }
  
    .reviewer-info,
    .review-details,
    .action-buttons {
      flex-wrap: wrap;
    }
  
    .rating-container,
    .action-section {
      white-space: initial;
    }
  
    .action-button {
      padding: 0 20px;
    }
  }