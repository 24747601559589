.title_travel{

    font-size: 25px;
    color: gray !important;
}

.travel_detail{

    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(169, 86, 188);
}

.title_cover{
    height: 60px;
}

.image-no-result{
    height: 70%;
    width: 70%;
    justify-content: center;
}

.text-no-result{
    text-align: center;
}
.extra-action-no-result{
    margin: auto;
    width: 20%;

}

.create-annonce{
    width: 100%;
    height: 100px;
    align-items: center;
    margin: auto;
    background-color: rgba(243, 243, 243, 0.862);
    display: flex;
    justify-content: center;
    gap: 10px;
}

.search-zone{
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    gap: 20px;
    justify-content: center;
    margin: auto;
    padding: 10px;

}

@media (max-width: 767px) {
    .search-zone{
        display: grid;
        grid-template-columns: 100%;
        width: 100%;
        gap: 20px;
        justify-content: center;
        margin: auto;
    
    }
    #main-body{
        display: grid;
        width: 90%;
        grid-template-columns: 100%;
        height: auto;
        justify-content: center;
        margin: auto;
    }
}

@media (width: 768px) {
    #main-body{
        display: grid;
        width: 90%;
        grid-template-columns: 100%;
        height: auto;
        justify-content: center;
        margin: auto;
    }
}

@media (min-width: 769px) {
    #main-body{
        display: grid;
        width: 90%;
        grid-template-columns: 2fr 7fr;
        height: auto;
        justify-content: center;
        margin: auto;
        max-width: 1024px;
    }
}



@media (min-width: 1440px) {
    #main-body{
        display: grid;
        width: 80%;
        min-height: 500px;
        grid-template-columns: 2fr 7fr;
        height: auto;
        justify-content: center;
        margin: auto;
        max-width: 1024px;
    }
}

@media (min-width: 2559px) {
    #main-body{
        display: grid;
        width: 45%;
        min-height: 500px;
        grid-template-columns: 2fr 7fr;
        height: auto;
        justify-content: center;
        margin: auto;
    }
}


.socials-buttons{

    width: 50%;
    margin: auto;
    align-items: center;
}

.dialog-box{
   
}
.boutons-action:hover{

    background-color: #2b7045;
    opacity: 10;

}



.search-sidebar{
    text-align: center;
    
}
