.dashboard-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.title-h1 {
    text-align: center;
    margin-bottom: 20px;
}

.dashboard-stats {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.stat-box {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.role-filter, .add-role {
    margin-top: 20px;
}

select {
    padding: 10px;
    margin-top: 10px;
}

.users-list {
    margin-top: 20px;
}

.users-list ul {
    list-style: none;
    padding: 0;
}

.users-list li {
    background-color: #f9f9f9;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
}

input {
    margin-right: 10px;
    padding: 5px;
}

.dashboard-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.dashboard-button:hover {
    background-color: #45a049;
}
