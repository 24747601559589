#checkmark-div{
    border-radius:200px; 
    height:200px;
     width:200px; 
     background: #F8FAF5; 
     margin: auto;

}


.msg-success {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
  }
.details-message {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size:20px;
    margin: 0;
  }
  @media (min-width: 990px) {
    
  }

 
.checkmark {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left: 50px;
}
.card-success {
  background: white;
  padding: 60px;
  display: inline-block;
  margin: auto;
  margin: 20px;
}

.img-felicitations{
    width: 30%;
    height: 30%;
}

.confirmation-msg{
    text-align: center;
    margin-top: 50px;
    color: #9ABC66;
}



@media (max-width: 990px) {

  .partners-div{
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    width: 90%;
    margin: auto;
}
    
}

@media (min-width: 990px) {

  .partners-div{
    justify-content: space-around;
    margin-top: 50px;
    width: 50%;
    margin: auto;
}
    
}