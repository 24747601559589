
.title-agencies {
    text-align: center;
    color: #333;
}

.agency-list {
    width: 100%;
    margin: 20px auto;
    padding: 0;
    list-style: none;
}
.agency-item {
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(127, 81, 144, 0.25);
    width: 100%;
}
.agency-item h2 {
    margin: 0;
    color: #2c3e50;
}
.agency-item p {
    margin: 5px 0 0;
    color: #7f8c8d;
}

.middle-info{
    width: 70%;
    padding-top: 20px;
    max-width: 1024px;
    margin: 0 auto;
    justify-content: center;
    min-height: 500px;
    margin-bottom: 30px;
}


.container-plan {
    max-width: 1024px;
    margin: auto;
}

.customer-info, .subscription-details, .payment-summary {
    margin-bottom: 20px;
}
.subscription-details table {
    width: 100%;
    border-collapse: collapse;
}
.subscription-details th, .subscription-details td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
.subscription-details th {
    background-color: #f6f1f8;
}
.total {
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
}
.pay-button {
    display: block;
    margin: 20px 0;
    margin-bottom: 20px;
    float: right;
    height: 60px;

}

.pay-button button {
    padding: 10px 20px;
    background-color: #5f2972;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    
    
}
.pay-button button:hover {
    background-color: #5f2972;
}