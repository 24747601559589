.page-wrapper { justify-content: center; background-color: #fff; display: flex; flex-direction: column; }
.content-container { justify-content: flex-end; background-color: #fff; display: flex; width: 100%; flex-direction: column; }
@media (max-width: 991px) { .content-container { max-width: 100%; } }
.hero-section { justify-content: center; background-color: #5f2972; display: flex; width: 100%; flex-direction: column; font-size: 16px; font-weight: 600; }
@media (max-width: 991px) { .hero-section { max-width: 100%; } }
.hero-image-container { display: flex; flex-direction: column; overflow: hidden; position: relative; min-height: 354px; width: 100%; justify-content: center; }
@media (max-width: 991px) { .hero-image-container { max-width: 100%; } }
.hero-image { position: absolute; inset: 0; height: 100%; width: 100%; object-fit: cover; object-position: center; }
.hero-content { position: relative; background: linear-gradient(180deg, rgba(38, 15, 47, 0.9) 39.12%, rgba(95, 41, 114, 0.36) 130.93%); backdrop-filter: blur(7.5px); display: flex; width: 100%; flex-direction: column; align-items: center; padding: 37px 80px 0; }
@media (max-width: 991px) { .hero-content { max-width: 100%; padding: 0 20px; } }
.nav-container { display: flex; width: 100%; max-width: 1660px; gap: 20px; color: #fff; justify-content: space-between; }
@media (max-width: 991px) { .nav-container { max-width: 100%; flex-wrap: wrap; } }
.logo { aspect-ratio: 2.08; object-fit: auto; object-position: center; width: 197px; max-width: 100%; }
.nav-links { justify-content: space-between; align-items: center; display: flex; padding-left: 8px; gap: 8px; margin: auto 0; }
@media (max-width: 991px) { .nav-links { flex-wrap: wrap; } }
.nav-item { font-family: Lexend, sans-serif; align-self: stretch; margin: auto 0; }
.user-actions { align-self: stretch; display: flex; gap: 16px; }
.separator { border-radius: 10px; background-color: #fff; width: 2px; height: 32px; margin: auto 0; }
.user-profile { justify-content: center; display: flex; gap: 8px; }
.profile-icon { aspect-ratio: 1; object-fit: auto; object-position: center; width: 48px; }
.profile-info { justify-content: center; display: flex; flex-direction: column; padding: 10px 8px; }
.profile-name { font-family: Lexend, sans-serif; }
.profile-indicator { border-radius: 20px; background-color: #fff; margin-top: 4px; height: 3px; }
.hero-title { color: #fff; margin: 51px 0 0 30px; font: 32px Lexend, sans-serif; }
@media (max-width: 991px) { .hero-title { max-width: 100%; margin-top: 40px; } }
.hero-subtitle { color: #fff; margin: 12px 0 0 31px; font: 500 20px Lexend, sans-serif; }
@media (max-width: 991px) { .hero-subtitle { max-width: 100%; } }
.search-container { border-radius: 20px; box-shadow: 0 4px 4px 0 rgba(127, 81, 144, 0.25); background-color: #fff; display: flex; margin-top: 66px; width: 1079px; max-width: 100%; gap: 20px; font-weight: 500; justify-content: space-between; padding: 8px 9px 0 8px; }
@media (max-width: 991px) { .search-container { flex-wrap: wrap; margin-top: 40px; } }
.search-fields { display: flex; gap: 16px; color: #a97bb9; }
@media (max-width: 991px) { .search-fields { flex-wrap: wrap; } }
.search-field { border-radius: 20px; display: flex; gap: 8px; white-space: nowrap; padding: 8px 16px 0; }
@media (max-width: 991px) { .search-field { white-space: initial; } }
.search-icon { aspect-ratio: 2; object-fit: auto; object-position: center; width: 24px; align-self: start; }
.search-label { font-family: Lexend, sans-serif; }
.search-separator { border-radius: 20px; background-color: #e1d2e6; align-self: start; margin-top: 4px; width: 2px; height: 16px; }
.search-button { font-family: Lexend, sans-serif; justify-content: center; border-radius: 10px; background-color: #b490c0; align-self: start; color: #fff; white-space: nowrap; padding: 8px 16px; }
@media (max-width: 991px) { .search-button { white-space: initial; } }
.main-content { align-self: center; margin-top: 64px; width: 80%; max-width: 1381px; padding: 0 20px; min-height: 700px; }
@media (max-width: 991px) { .main-content { max-width: 100%; margin-top: 40px; width: 100%; } }
.content-columns { gap: 20px; display: flex; }
@media (max-width: 991px) { .content-columns { flex-direction: column; align-items: stretch; gap: 0; } }
.profile-column { /*max-height: 500px;*/ display: flex; flex-direction: column; line-height: normal; width: 25%; margin-left: 0; }
@media (max-width: 991px) { .profile-column { width: 100%; } }
.profile-card { display: flex; flex-grow: 1; padding-bottom: 5px; flex-direction: column; color: #7f5190; font-weight: 600; margin-bottom: 20px; }
@media (max-width: 991px) { .profile-card { margin-top: 32px; } }
.profile-details { background-color: #fff; display: flex; flex-direction: column; padding: 16px 16px 32px; max-width: 700px;}
.profile-avatar { aspect-ratio: 1; object-fit: auto; object-position: center; width: 48px; box-shadow: 0 2px 0 0 rgba(127, 81, 144, 0.25); align-self: center; }
.profile-name { align-self: center; margin-top: 16px; font: 16px Lexend, sans-serif; }
.profile-role { color: #b490c0; align-self: center; font: 14px Lexend, sans-serif; }
.profile-divider { border-radius: 20px; background-color: #f6f1f8; margin-top: 16px; height: 2px; }
.profile-description-title { margin-top: 16px; font: 20px Lexend, sans-serif; }
.profile-description { color: #b490c0; margin-top: 8px; font: 400 14px Lexend, sans-serif; }
.profile-info-list { justify-content: center; align-items: start; display: flex; margin-top: 32px; flex-direction: column; font-size: 12px; color: #302f30; padding: 0 16px; }
@media (max-width: 991px) { .profile-info-list { padding-right: 20px; } }
.profile-info-item { display: flex; gap: 16px; margin-bottom: 5px; }
.profile-info-icon { aspect-ratio: 1; object-fit: auto; object-position: center; width: 32px; }
.profile-info-text { font-family: Lexend, sans-serif; margin: auto 0; }
.trips-column { display: flex; flex-direction: column; line-height: normal; width: 57%; margin-left: 20px; }
@media (max-width: 991px) { .trips-column { width: 100%; } }
.trips-container { display: flex; flex-grow: 1; padding-top: 16px; flex-direction: column;  margin-right: 20px;}
@media (max-width: 991px) { .trips-container { max-width: 100%; margin-top: 32px; } }
.trips-title { color: #7f5190; align-self: start; margin-left: 16px; font: 600 20px Lexend, sans-serif; }
@media (max-width: 991px) { .trips-title { margin-left: 10px; } }
.trips-list { justify-content: flex-end; display: flex; margin-top: 26px; flex-direction: column; padding: 0 4px 31px; }
@media (max-width: 991px) { .trips-list { max-width: 100%; } }
.trip-card { padding-bottom: 16px; border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(127, 81, 144, 0.25); background-color: #fff; display: flex; flex-direction: column; }
@media (max-width: 991px) { .trip-card { max-width: 100%; } }
.trip-header { background-color: #f6f1f8; display: flex; gap: 20px; padding: 16px; }
@media (max-width: 991px) { .trip-header { flex-wrap: wrap; } }
.trip-icon { aspect-ratio: 1; object-fit: auto; object-position: center; width: 48px; }
.trip-details { justify-content: space-between; align-self: start; display: flex; gap: 20px; flex: 1; }
@media (max-width: 991px) { .trip-details { max-width: 100%; flex-wrap: wrap; } }
.trip-locations { align-self: start; display: flex; flex-direction: column; }
.trip-location { display: flex; gap: 8px; font-size: 16px; color: #7f5190; font-weight: 500; white-space: nowrap; }
@media (max-width: 991px) { .trip-location { white-space: initial; } }
.location-icon { aspect-ratio: 1; object-fit: auto; object-position: center; width: 20px; }
.location-name { font-family: Lexend, sans-serif; }
.trip-date { color: #b490c0; align-self: center; margin-top: 8px; font: 400 12px Lexend, sans-serif; }
.trip-arrow { aspect-ratio: 1.16; object-fit: auto; object-position: center; width: 22px; fill: #7f5190; stroke-width: 0.2px; stroke: #7f5190; border: 0 solid rgba(127, 81, 144, 1); margin: auto 0; }
.trip-info { justify-content: space-between; display: flex; margin-top: 16px; width: 100%; gap: 8px; font-size: 12px; color: #b490c0; font-weight: 400; padding: 0 16px; }
@media (max-width: 991px) { .trip-info { max-width: 100%; flex-wrap: wrap; padding-right: 20px; } }
.trip-specs { display: flex; flex-direction: column; }
.trip-spec { display: flex; gap: 8px; }
.spec-icon { aspect-ratio: 1; object-fit: auto; object-position: center; width: 20px; }
.spec-text { font-family: Lexend, sans-serif; margin: auto 0; }
.trip-notes { display: flex; gap: 8px; margin: auto 0; }
.notes-icon { aspect-ratio: 1; object-fit: auto; object-position: center; width: 20px; align-self: start; }
.notes-text { font-family: Lexend, sans-serif; flex: 1; }
.trip-footer { display: flex; margin-top: 16px; flex-direction: column; font-size: 14px; padding: 0 16px; }
@media (max-width: 991px) { .trip-footer { max-width: 100%; } }
.footer-divider { border-radius: 20px; background-color: #f6f1f8; height: 2px; }
@media (max-width: 991px) { .footer-divider { max-width: 100%; } }
.footer-content { justify-content: space-between; display: flex; margin-top: 16px; width: 100%; gap: 20px; }
@media (max-width: 991px) { .footer-content { max-width: 100%; flex-wrap: wrap; } }
.driver-info { justify-content: center; display: flex; gap: 8px; font-weight: 600; white-space: nowrap; }
@media (max-width: 991px) { .driver-info { white-space: initial; } }
.driver-avatar { aspect-ratio: 1; object-fit: auto; object-position: center; width: 44px; }
.driver-details { justify-content: center; display: flex; flex-direction: column; padding: 4px 0; }
@media (max-width: 991px) { .driver-details { white-space: initial; } }
.driver-name { color: #7f5190; font-family: Lexend, sans-serif; }
.driver-rating { display: flex; gap: 4px; }
@media (max-width: 991px) { .driver-rating { white-space: initial; } }
.rating-score { color: #7f5190; font-family: Lexend, sans-serif; }
.rating-star { aspect-ratio: 1; object-fit: auto; object-position: center; width: 14px; margin: auto 0; }
.rating-count { color: #b490c0; font-family: Lexend, sans-serif; }
.view-trip-button { font-family: Lexend, sans-serif; justify-content: center; border-radius: 15px; background-color: #7f5190; align-self: start; color: #fff; font-weight: 400; padding: 12px 16px; }
@media (max-width: 991px) { .view-trip-button { padding: 0 20px; } }
.reviews-column { display: flex; flex-direction: column; line-height: normal; width: 25%; margin-left: 20px; }
@media (max-width: 991px) { .reviews-column { width: 100%; } }
.reviews-container { border-radius: 20px; box-shadow: 0 4px 4px 0 rgba(180, 144, 192, 0.25); background-color: #fff; display: flex; width: 100%; flex-direction: column; margin: 0 auto; padding: 16px; }
@media (max-width: 991px) { .reviews-container { margin-top: 32px; } }
.review-item { display: flex; flex-direction: column; margin-bottom: 16px; }
.review-header { display: flex; gap: 8px; color: #7f5190; }
.reviewer-avatar { aspect-ratio: 1; object-fit: auto; object-position: center; width: 32px; box-shadow: 0 2px 0 0 rgba(127, 81, 144, 0.25); }
.reviewer-info { justify-content: space-between; display: flex; gap: 4px; margin: auto 0; }
.reviewer-name { font: 500 14px Lexend, sans-serif; color: #7f5190; }
.reviewer-rating { display: flex; gap: 0; font-size: 12px; font-weight: 600; white-space: nowrap; margin: auto 0; }
@media (max-width: 991px) { .reviewer-rating { white-space: initial; } }
.rating-value { font-family: Lexend, sans-serif; }
.rating-star { aspect-ratio: 1; object-fit: auto; object-position: center; width: 14px; align-self: start; }
.review-content { display: flex; padding-left: 40px; flex-direction: column; font-weight: 400; }
@media (max-width: 991px) { .review-content { padding-left: 20px; } }
.review-text { color: #b490c0; align-self: start; font: 12px Lexend, sans-serif; }
.review-date { color: #7f5190; align-self: end; font: 10px Lexend, sans-serif; }
.review-divider { border-radius: 20px; background-color: #f6f1f8; margin-top: 8px; height: 2px; }

