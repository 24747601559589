.contenu-principal-mlearning{
    padding-top: 30px;
    width: 80%;
    margin: auto;
    min-height: 700px;
    max-width: 1024px;
}

.details-history{
    background-color: #ffff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
    min-height: 150px;
}

.details-history-more{
    margin-top: 10px;
    background-color: #f6f1f8;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
    border-radius: 10px;

}

.details-history-good-inputs{
    margin-top: 10px;
    background-color: #e2eed9;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
    border-radius: 10px;

}

.details-history-actions{
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
    border-radius: 10px;

}

.details-history-more-item{
    text-align: center;
    margin: 10px;
    padding: 5px;
    border-radius: 10px;
    margin-left: 10px;
}

.btn-effect:active{
    background-color: #a370b5; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}