
.titre-about{
    color: #7F5190;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.description-about{
    color: #A77CB6;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}