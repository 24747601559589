


#content-body{
    margin-top: 10px;
}

.dimension-colis{
    font-size: 10px;
}
@media (min-width: 768px) {
    #header{
        margin-top: 30px;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        max-width: 1024px;
        margin: auto;
    }
    #content-part{
        width: 80%;
        margin: auto;
    }

}
@media (max-width: 768px) {
    #header{
        margin-top: 30px;
        display: grid;
        width: 100%;
        grid-template-columns: 100%;
        grid-template-columns: 1fr 1fr;
        margin-top: 20px;
    }

    #content-part{
        width: 80%;
        margin-left: 10px;
        margin-right: 10px;
        margin: auto;

    }
}

@media (min-width: 1440px) {
    #content-part{
        width: 80%;
        margin: auto;
    }
    
}

#historic-discussion{
    max-height: 600px;
    overflow: scroll;
    padding: 12px;
}

/*Commentaire zone*/

.commentaire{
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.commentaire-header{
    display: flex;
    flex-direction: row;
    color: var(--monocromatic-color);
}

.commentaire-body{
    height: auto;
    padding-bottom: 20px;
    padding-top: 5px;
    
}

.bouton-voir{
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--3-Secondair, #F6F1F8);  
}