
.user-reservation{
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    min-height: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    padding-bottom: 5px;
    
}

#side-reservation{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    background-color: ghostwhite;
    justify-content: center;
    color: var(--primary-color);
    padding: 5px;
}

#right-reservation{

    padding: 5px;
   
}

#actions{
    display: flex;
    width: 100%;
    padding-left: 5px;

}
#actions li{
    display: inline;
    margin-right: 10px;
}
