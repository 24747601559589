
#content-body{
    margin-top: 10px;
}

@media (min-width: 768px) {
    #header{
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    #content-part{
        width: 80%;
        margin: auto;
    }

    .details-contenu{
        margin-top: 20px;
        display: grid;
        grid-template-columns: 2fr 5fr;
        max-width: 1024px;
        justify-content: center;
        min-height: 500px;
        margin: auto;
    }
}
@media (max-width: 768px) {
    #header{
        display: grid;
        width: 100%;
        grid-template-columns: 100%;
    }

    #content-part{
        width: 80%;
        margin-left: 10px;
        margin-right: 10px;
        margin: auto;

    }

    .details-contenu{
        margin-top: 20px;
        display: grid;
        grid-template-columns: 100%;
        max-width: 1024px;
        justify-content: center;
    }
}

@media (min-width: 1440px) {
    #content-part{
        width: 80%;
        margin: auto;
    }
    
}

#historic-discussion{
    max-height: 600px;
    overflow: scroll;
}

/*Commentaire zone*/

.commentaire{
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.commentaire-header{
    display: flex;
    flex-direction: row;
    color: var(--monocromatic-color);
}

.commentaire-body{
    height: auto;
    padding-bottom: 20px;
    padding-top: 5px;
    
}


.titre-description{
    color: var(--1-Principal, #7F5190);
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}