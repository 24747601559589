
.account-info-container { display: flex; max-width: 1024px; flex-direction: column; }
.info-card { border-radius: 20px; box-shadow: 0 4px 8px rgba(180, 144, 192, 0.25); background-color: #fff; padding: 32px; width: 100%; }
.card-title { color: #7f5190; font: 500 24px Lexend, sans-serif; }
.profile-info-account { margin-top: 32px; display: flex; gap: 20px; }
.profile-image { width: 144px; aspect-ratio: 1; object-fit: cover; box-shadow: 0 4px 0 rgba(127, 81, 144, 0.25); }
.profile-details-account { display: flex; flex-direction: column; flex-grow: 1; }
.info-label { color: #7f5190; font: 600 16px Lexend, sans-serif; margin-top: 16px; }
.info-value { border-radius: 5px; background-color: #f6f1f8; color: #b490c0; text-align: center; padding: 10px 0; font: 400 14px Lexend, sans-serif; margin-top: 8px; }
.name-container { display: flex; gap: 16px; margin-top: 16px; }
.name-field { display: flex; flex-direction: column; flex: 1; }
.description-card { margin-top: 24px; padding: 16px; }
.description-text-account { border-radius: 10px; background-color: #f6f1f8; color: #cbadd6; padding: 10px; font: 400 12px Lexend, sans-serif; margin-top: 16px; }
.social-card { margin-top: 24px; }
.social-link { display: flex; align-items: center; gap: 10px; margin-top: 16px; }
.social-icon { width: 24px; height: 24px; }
.social-name { color: #7f5190; font: 600 16px Lexend, sans-serif; }
.twitter-icon-wrapper { align-items: center; border-radius: 12px; background-color: #000; display: flex; justify-content: center; width: 24px; height: 24px; padding: 4px; }
.twitter-icon { width: 16px; height: 16px; }
.visually-hidden { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; border: 0; }

@media (max-width: 991px) {
  .info-card { padding: 20px; }
  .profile-info-account { flex-direction: column; }
  .profile-image { margin-top: 32px; }
  .profile-details-account { margin-top: 32px; }
  .name-container { flex-wrap: wrap; }
}

.button-mise-a-jour{
  margin-top: 10px;
  border-radius: 10px;
  border: 0.5px solid #7C7C7C;
  background: #7F5190;
  max-width: 200px;
  height: 40px;
  color: #fff;
  padding: 2px;
  text-align: center;
  float: right;

}


.PhoneInputInput{

  background-color:  #f6f1f8;
}