.demande-card {
    padding-bottom: 16px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
    background-color: #fff;
    display: flex;
    max-width: 800px;
    flex-direction: column;
    font-weight: 400;
    margin-bottom: 10px;
  }
  
  .demande-header {
    background-color: #f6f1f8;
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 16px;
    color: #7f5190;
    font-weight: 500;
    white-space: nowrap;
    justify-content: center;
    padding: 16px 32px;
  }
  
  .demande-location-container {
    justify-content: space-between;
    display: flex;
    width: 100%;
    gap: 20px;
  }
  
  .demande-location {
    display: flex;
    gap: 8px;
  }
  
  .demande-location-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
  }
  
  .demande-location-name {
    font-family: Lexend, sans-serif;
  }
  
  .demande-arrow-icon {
    aspect-ratio: 1.16;
    object-fit: auto;
    object-position: center;
    width: 21px;
    fill: #7f5190;
    stroke-width: 0.2px;
    stroke: #7f5190;
    border: 0 solid #7f5190;
    align-self: start;
  }
  
  .demande-info-container {
    justify-content: space-between;
    display: flex;
    margin-top: 16px;
    width: 100%;
    gap: 8px;
    font-size: 12px;
    padding: 0 16px;
  }
  
  .demande-weight-info {
    align-self: start;
    display: flex;
    gap: 8px;
    color: #b490c0;
  }
  
  .demande-weight-text {
    font-family: Lexend, sans-serif;
    margin: auto 0;
  }
  
  .demande-description-container {
    justify-content: end;
    display: flex;
    gap: 8px;
    color: #b490c0;
    padding: 0 8px;
  }
  
  .quote-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
    align-self: start;
  }
  
  .demande-description-text {
    font-family: Lexend, sans-serif;
  }
  
  .demande-footer {
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
    padding: 0 16px;
  }
  
  .divider {
    border-radius: 20px;
    background-color: #f6f1f8;
    height: 2px;
  }
  
  .demande-footer-content {
    justify-content: space-between;
    display: flex;
    margin-top: 16px;
    width: 100%;
    gap: 20px;
  }
  
  .demande-timestamp {
    display: flex;
    gap: 4px;
    font-size: 10px;
    color: #7f5190;
    margin: auto 0;
  }
  
  .clock-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 16px;
  }
  
  .timestamp-text {
    font-family: Lexend, sans-serif;
  }
  
  .action-buttons {
    justify-content: center;
    display: flex;
    gap: 16px;
    font-size: 14px;
  }
  
  .delete-button {
    justify-content: center;
    border-radius: 15px;
    background-color: #f6f1f8;
    display: flex;
    gap: 10px;
    color: #7f5190;
    white-space: nowrap;
    flex: 1;
    padding: 12px 16px;
  }
  
  .delete-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 16px;
    align-self: start;
  }
  
  .delete-text {
    font-family: Lexend, sans-serif;
  }
  
  .demande-view-responses-button {
    justify-content: center;
    border-radius: 15px;
    background-color: #7f5190;
    display: flex;
    gap: 10px;
    color: #fff;
    flex: 1;
    padding: 12px 16px;
  }
  
  .view-responses-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 16px;
    align-self: start;
  }
  
  .view-responses-text {
    font-family: Lexend, sans-serif;
  }
  
  @media (max-width: 991px) {
    .header {
      max-width: 100%;
      white-space: initial;
      padding: 0 20px;
    }
  
    .location-container {
      max-width: 100%;
      flex-wrap: wrap;
      white-space: initial;
    }
  
    .location {
      white-space: initial;
    }
  
    .info-container {
      max-width: 100%;
      flex-wrap: wrap;
    }
  
    .footer {
      max-width: 100%;
    }
  
    .divider {
      max-width: 100%;
    }
  
    .footer-content {
      max-width: 100%;
      flex-wrap: wrap;
    }
  
    .delete-button {
      white-space: initial;
      padding: 0 20px;
    }
  }