/**reservation**/

.contact-form {
    background-color: #fff;
    display: flex;
    max-width: 801px;
    width: 100%;
    flex-direction: column;
    padding: 16px;
  }
  
  .form-title {
    color: #7f5190;
    align-self: start;
    font: 500 20px Lexend, sans-serif;
  }
  
  .form-description {
    color: #b490c0;
    align-self: start;
    margin-top: 32px;
    font: 400 14px Lexend, sans-serif;
  }
  
  .form-divider {
    border-radius: 20px;
    background-color: #f6f1f8;
    margin-top: 32px;
    height: 2px;
  }
  
  .form-fields {
    display: flex;
    margin-top: 32px;
    padding-right: 37px;
    gap: 20px;
    justify-content: space-between;
  }
  
  .field-group {
    display: flex;
    flex-direction: column;
  }
 
  
  .field-label {
    color: #7f5190;
    font: 500 16px Lexend, sans-serif;
  }
  
  .field-input {
    border-radius: 10px;
    background-color: #f6f1f8;
    margin-top: 16px;
    color: #cbadd6;
    padding: 10px;
    font: 400 12px Lexend, sans-serif;
    border: none;
  }
  
  .dimensions-group {
    display: flex;
    margin-top: 16px;
    gap: 8px;
    font-size: 12px;
    color: #cbadd6;
    font-weight: 400;
  }
  
  .dimension-input {
    font-family: Lexend, sans-serif;
    border-radius: 10px;
    background-color: #f6f1f8;
    padding: 10px;
    border: none;
  }
  
  .description-label {
    color: #7f5190;
    margin-top: 32px;
    font: 500 16px Lexend, sans-serif;
  }
  
  .description-input {
    border-radius: 10px;
    background-color: #f6f1f8;
    margin-top: 16px;
    color: #cbadd6;
    padding: 10px 10px 48px;
    font: 400 12px Lexend, sans-serif;
    border: none;
    width: 100%;
    resize: vertical;
  }
  
  .form-actions {
    display: flex;
    margin-top: 16px;
    gap: 16px;
    font-size: 16px;
    font-weight: 400;
  }
  
  .cancel-button {
    font-family: Lexend, sans-serif;
    border-radius: 10px;
    background-color: #f6f1f8;
    color: #7f5190;
    flex: 1;
    padding: 12px 16px;
    border: none;
    cursor: pointer;
  }
  
  .submit-button {
    font-family: Lexend, sans-serif;
    border-radius: 10px;
    background-color: #7f5190;
    color: #f6f1f8;
    flex: 1;
    padding: 12px 16px;
    border: none;
    cursor: pointer;
  }
  
  @media (max-width: 991px) {
    .form-title,
    .form-description,
    .form-divider,
    .description-label,
    .description-input {
      max-width: 100%;
    }
  
    .form-fields {
      flex-wrap: wrap;
      padding-right: 20px;
    }
  
    .dimensions-group {
      white-space: initial;
    }
  
    .dimension-input {
      padding-right: 20px;
      white-space: initial;
    }
  
    .form-actions {
      max-width: 100%;
      flex-wrap: wrap;
    }
  
    .cancel-button,
    .submit-button {
      padding: 12px 20px;
    }
  }

  .input-kilos{
    margin-top: 16px;
    font-size: 12px;
  }

  @media (max-width: 991px) {
    
    .dimensions-group {
      display: grid;
      margin-top: 16px;
      gap: 8px;
      font-size: 12px;
      color: #cbadd6;
      font-weight: 400;
    }
    
  }

  @media (min-width: 991px) {
    
    .dimensions-group {
      display: flex;
      margin-top: 16px;
      gap: 8px;
      font-size: 12px;
      color: #cbadd6;
      font-weight: 400;
    }
    
  }