


@media (min-width: 999px) {
    
    .checkout-div{

        display: grid;
        grid-template-columns: 7fr 4fr;
        width: 70%;
        justify-content: center;
        margin: auto;
        min-height: 400px;
        margin-top: 10px;
        gap: 30px;
        max-width: 1024px;
        
    }
}

@media (max-width: 999px) {
    
    .checkout-div{

        display: grid;
        grid-template-columns: 100%;
        width: 90%;
        justify-content: center;
        margin: auto;
        min-height: 300px;
        margin-top: 10px;
        gap: 30px;
        max-width: 1024px;
        
    }
}


.right-panel{
    border-radius: 10px;
    box-shadow: 4px 4px 4px 4px rgba(127, 81, 144, 0.25);
    padding: 20px;
    max-height: 600px;
}

.main-content-paiement{
    border-radius: 10px;
    box-shadow: 4px 4px 4px 4px rgba(127, 81, 144, 0.25);
    padding: 20px;
}

.top-paiement{
    height: 300px;
    display: inline;
    gap: 10px;
    
   
}

.coupon-input{
    height: 20px;

}

.coupon-div{
    display: grid;
    grid-template-columns: 4fr 2fr;
    margin-top: 10px;
    margin-bottom: 10px;
}

.pricing-detail{
    display: flex;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    padding: 5px;
    margin-top: 10px;
}

.label-checkout { 
   /* color: #7f5190;*/
    font-size: 16px;
     /*font: 600 20px Lexend, sans-serif; */
    margin-top: 16px; 
}

.bouton-paiement{
    margin-right: 20px;
    margin-top: 10px;

}

.titre-checkout { color: #7f5190; font: 300 20px Lexend, sans-serif; }

.paiement-option{
    display: flex;
    align-items: center;
    gap: 10px;
}

.img-option-paiement{
    width: 20%;
    height: auto;
}

#orange-money{
    vertical-align: middle;
}

.option-img-checkbox{
    display: flex;
    align-items: center;
}

.titre-option-paiement{
    margin-left: 10px;
}
.single-checkbox{
    transform: scale(1.5); /* 1.5x larger */
    -webkit-transform: scale(1.5); /* Safari/older browsers support */
    background-color: #7f5190;
}