.profile-name-review {
    color: #ffffff;
    margin-top: 16px;
    font: 16px Lexend, sans-serif;
  }

  .review-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

