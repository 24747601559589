

@media (min-width: 768px) {
    .main-container{
        display: grid;
        grid-template-columns: 80%;
    }
}

@media (max-width: 768px) {
    .main-container{
        display: grid;
        grid-template-columns: 100%;
    }
}

#img-illustration{
    margin-left: auto;
    margin-right: auto;
}

.sous-titre{
    color: #A77CB6;
    font-family: Poppins;
    text-align: center;
    width: 100%;
}

.objet-demande{
    background-color: #f6f1f8;
}