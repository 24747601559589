.main-container-travel {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #fff;
  }
  
  .hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #5f2972;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
  }
  
  @media (max-width: 991px) {
    .hero-section {
      max-width: 100%;
    }
  }
  
  .hero-background {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    min-height: 354px;
    width: 100%;
    justify-content: center;
  }
  
  @media (max-width: 991px) {
    .hero-background {
      max-width: 100%;
    }
  }
  
  .hero-image {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .hero-content {
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(38, 15, 47, 0.9) 39.12%,
      rgba(95, 41, 114, 0.36) 130.93%
    );
    backdrop-filter: blur(7.5px);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 37px 80px 0;
  }
  
  @media (max-width: 991px) {
    .hero-content {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  
  .nav-container {
    display: flex;
    width: 100%;
    max-width: 1660px;
    gap: 20px;
    color: #fff;
    justify-content: space-between;
  }
  
  @media (max-width: 991px) {
    .nav-container {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  
  .logo {
    aspect-ratio: 2.08;
    object-fit: auto;
    object-position: center;
    width: 197px;
    max-width: 100%;
  }
  
  .nav-links {
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 8px;
    gap: 8px;
    margin: auto 0;
  }
  
  @media (max-width: 991px) {
    .nav-links {
      flex-wrap: wrap;
    }
  }
  
  .nav-link {
    font-family: Lexend, sans-serif;
    align-self: stretch;
    margin: auto 0;
  }
  
  .auth-section {
    align-self: stretch;
    display: flex;
    gap: 16px;
  }
  
  .auth-divider {
    border-radius: 10px;
    background-color: #fff;
    width: 2px;
    height: 32px;
    margin: auto 0;
  }
  
  .auth-button {
    justify-content: center;
    display: flex;
    gap: 8px;
  }
  
  .auth-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 48px;
  }
  
  .auth-text {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px 8px;
  }
  
  .auth-label {
    font-family: Lexend, sans-serif;
  }
  
  .auth-underline {
    border-radius: 20px;
    background-color: #fff;
    margin-top: 4px;
    height: 3px;
  }
  
  .hero-title {
    color: #fff;
    margin: 51px 0 0 30px;
    font: 32px Lexend, sans-serif;
  }
  
  @media (max-width: 991px) {
    .hero-title {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  
  .hero-subtitle {
    color: #fff;
    margin: 12px 0 0 31px;
    font: 500 20px Lexend, sans-serif;
  }
  
  @media (max-width: 991px) {
    .hero-subtitle {
      max-width: 100%;
    }
  }
  
  .search-container {
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(127, 81, 144, 0.25);
    background-color: #fff;
    display: flex;
    margin-top: 66px;
    width: 1079px;
    max-width: 100%;
    gap: 20px;
    font-weight: 500;
    justify-content: space-between;
    padding: 8px 9px 0 8px;
  }
  
  @media (max-width: 991px) {
    .search-container {
      flex-wrap: wrap;
      margin-top: 40px;
    }
  }
  
  .search-fields {
    display: flex;
    gap: 16px;
    color: #a97bb9;
  }
  
  @media (max-width: 991px) {
    .search-fields {
      flex-wrap: wrap;
    }
  }
  
  .search-field {
    border-radius: 20px;
    display: flex;
    gap: 8px;
    white-space: nowrap;
    padding: 8px 16px 0;
  }
  
  @media (max-width: 991px) {
    .search-field {
      white-space: initial;
    }
  }
  
  .search-icon {
    aspect-ratio: 2;
    object-fit: auto;
    object-position: center;
    width: 24px;
    align-self: start;
  }
  
  .search-label {
    font-family: Lexend, sans-serif;
  }
  
  .search-divider {
    border-radius: 20px;
    background-color: #e1d2e6;
    align-self: start;
    margin-top: 4px;
    width: 2px;
    height: 16px;
  }
  
  .search-button {
    font-family: Lexend, sans-serif;
    justify-content: center;
    border-radius: 10px;
    background-color: #b490c1;
    align-self: start;
    color: #fff;
    white-space: nowrap;
    padding: 8px 16px;
  }
  
  @media (max-width: 991px) {
    .search-button {
      white-space: initial;
    }
  }
  
  .content-section {
    align-self: center;
    margin-top: 64px;
    width: 1287px;
    max-width: 100%;
  }
  
  @media (max-width: 991px) {
    .content-section {
      margin-top: 40px;
    }
  }
  
  .content-container {
    gap: 20px;
    display: flex;
  }
  
  @media (max-width: 991px) {
    .content-container {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  
  .main-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 81%;
    margin-left: 0px;
  }
  
  @media (max-width: 991px) {
    .main-column {
      width: 100%;
    }
  }
  
  .main-content {
    flex-grow: 1;
  }
  
  @media (max-width: 991px) {
    .main-content {
      max-width: 100%;
      margin-top: 32px;
    }
  }
  
  .content-row {
    gap: 20px;
    display: flex;
  }
  
  @media (max-width: 991px) {
    .content-row {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  
  .sidebar-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 25%;
    margin-left: 0px;
  }
  
  @media (max-width: 991px) {
    .sidebar-column {
      width: 100%;
    }
  }
  
  .sidebar {
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    padding: 80px 16px 16px;
  }
  
  @media (max-width: 991px) {
    .sidebar {
      margin-top: 32px;
    }
  }
  
  .sidebar-title {
    color: #7f5190;
    margin-top: 8px;
    font: 500 20px Lexend, sans-serif;
  }
  
  .sidebar-item {
    display: flex;
    margin-top: 16px;
    gap: 8px;
    font-size: 16px;
    color: #7f5190;
    font-weight: 500;
    padding: 0 4px;
  }
  
  .sidebar-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 16px;
    margin: auto 0;
  }
  
  .sidebar-text {
    font-family: Lexend, sans-serif;
    flex: 1;
  }
  
  .sidebar-checkbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
  }
  
  .checkbox {
    stroke-width: 1.2px;
    border: 1px solid #7f5190;
    border-radius: 50%;
    height: 16px;
  }
  
  .sidebar-divider {
    border-radius: 20px;
    background-color: #f6f1f8;
    margin-top: 32px;
    height: 2px;
  }
  
  .main-column-content {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 75%;
    margin-left: 20px;
  }
  
  @media (max-width: 991px) {
    .main-column-content {
      width: 100%;
    }
  }
  
  .content-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  
  @media (max-width: 991px) {
    .content-wrapper {
      max-width: 100%;
      margin-top: 32px;
    }
  }
  
  .tab-container {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    padding: 16px 60px;
  }
  
  @media (max-width: 991px) {
    .tab-container {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  
  .tab-wrapper {
    display: flex;
    width: 276px;
    max-width: 100%;
    gap: 20px;
    justify-content: space-between;
  }
  
  .tab-inactive {
    color: #b490c0;
    font-family: Lexend, sans-serif;
  }
  
  .tab-active {
    display: flex;
    flex-direction: column;
    color: #7f5190;
  }
  
  .tab-text {
    font-family: Lexend, sans-serif;
  }
  
  .tab-indicator {
    border-radius: 20px;
    background-color: #7f5190;
    margin-top: 4px;
    height: 3px;
  }
  
  .card-container {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    padding: 2px 5px;
  }
  
  @media (max-width: 991px) {
    .card-container {
      max-width: 100%;
    }
  }
  
  .card {
    padding-bottom: 16px;
    border-radius: 20px;
    box-shadow: 0px 4px 8px 0px rgba(127, 81, 144, 0.25);
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  @media (max-width: 991px) {
    .card {
      max-width: 100%;
    }
  }
  
  .card-header {
    background-color: #f6f1f8;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0;
  }
  
  @media (max-width: 991px) {
    .card-header {
      max-width: 100%;
    }
  }
  
  .user-info {
    display: flex;
    gap: 20px;
  }
  
  @media (max-width: 991px) {
    .user-info {
      flex-wrap: wrap;
    }
  }
  
  .user-avatar {
    justify-content: center;
    align-self: start;
    display: flex;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }
  
  @media (max-width: 991px) {
    .user-avatar {
      white-space: initial;
    }
  }
  
  .avatar {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 44px;
  }
  
  .user-details {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 4px 0;
  }
  
  @media (max-width: 991px) {
    .user-details {
      white-space: initial;
    }
  }
  
  .user-name {
    color: #7f5190;
    font-family: Lexend, sans-serif;
  }
  
  .user-rating {
    display: flex;
    gap: 4px;
  }
  
  @media (max-width: 991px) {
    .user-rating {
      white-space: initial;
    }
  }
  
  .rating-value {
    color: #7f5190;
    font-family: Lexend, sans-serif;
  }
  
  .rating-star {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 14px;
    margin: auto 0;
  }
  
  .rating-count {
    color: #b490c0;
    font-family: Lexend, sans-serif;
  }
  
  .trip-info {
    justify-content: space-between;
    display: flex;
    gap: 20px;
    font-size: 16px;
    color: #7f5190;
    font-weight: 500;
    flex: 1;
    padding: 13px 16px;
  }
  
  @media (max-width: 991px) {
    .trip-info {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  .trip-location {
    display: flex;
    gap: 8px;
  }
  
  .location-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
  }
  
  .location-name-trajet {
    font-family: Lexend, sans-serif;
  }
  
  .trip-arrow {
    aspect-ratio: 1.11;
    object-fit: auto;
    object-position: center;
    width: 21px;
    fill: #7f5190;
    stroke-width: 0.2px;
    stroke: #7f5190;
    align-self: start;
  }
  
  .card-divider {
    border-radius: 20px;
    background-color: #f6f1f8;
    margin-top: 16px;
    height: 2px;
  }
  
  @media (max-width: 991px) {
    .card-divider {
      max-width: 100%;
    }
  }
  
  .card-details {
    justify-content: space-between;
    margin-top: 16px;
    padding: 0 16px;
  }
  
  @media (max-width: 991px) {
    .card-details {
      max-width: 100%;
      padding-right: 20px;
    }
  }
  
  .details-row {
    gap: 20px;
    display: flex;
  }
  
  @media (max-width: 991px) {
    .details-row {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  
  .details-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 0px;
  }
  
  @media (max-width: 991px) {
    .details-column {
      width: 100%;
    }
  }
  
  .details-list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 12px;
    color: #b490c0;
    font-weight: 400;
  }
  
  @media (max-width: 991px) {
    .details-list {
      margin-top: 40px;
    }
  }
  
  .details-item {
    display: flex;
    gap: 8px;
  }
  
  .details-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
  }
  
  .details-text {
    font-family: Lexend, sans-serif;
    margin: auto 0;
  }
  
  .description-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
  }
  
  @media (max-width: 991px) {
    .description-column {
      width: 100%;
    }
  }
  
  .description {
    align-self: stretch;
    display: flex;
    gap: 8px;
    font-size: 12px;
    color: #b490c0;
    font-weight: 400;
    margin: auto 0;
  }
  
  @media (max-width: 991px) {
    .description {
      margin-top: 40px;
    }
  }
  
  .description-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
    align-self: start;
  }
  
  .description-text {
    font-family: Lexend, sans-serif;
    flex: 1;
  }
  
  .card-footer {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    font-weight: 400;
    padding: 0 16px;
  }
  
  @media (max-width: 991px) {
    .card-footer {
      max-width: 100%;
    }
  }
  
  .footer-divider {
    border-radius: 20px;
    background-color: #f6f1f8;
    height: 2px;
  }
  
  @media (max-width: 991px) {
    .footer-divider {
      max-width: 100%;
    }
  }
  
  .footer-content {
    padding-left: 16px;
    justify-content: space-between;
    display: flex;
    margin-top: 16px;
    width: 100%;
    gap: 16px;
  }
  
  @media (max-width: 991px) {
    .footer-content {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  
  .timestamp {
    justify-content: center;
    display: flex;
    gap: 4px;
    font-size: 10px;
    color: #7f5190;
    margin: auto 0;
  }
  
  .timestamp-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 16px;
  }
  
  .timestamp-text {
    font-family: Lexend, sans-serif;
  }
  
  .view-button {
    border-radius: 15px;
    background-color: #7f5190;
    color: #fff;
    justify-content: center;
    padding: 12px 16px;
    font: 14px Lexend, sans-serif;
  }
  
  .trip-card {
    padding-bottom: 16px;
    border-radius: 20px;
    box-shadow: 0px 4px 8px 0px rgba(127, 81, 144, 0.25);
    background-color: #fff;
    display: flex;
    margin-top: 32px;
    flex-direction: column;
  }
  
  @media (max-width: 991px) {
    .trip-card {
      max-width: 100%;
    }
  }
  
  .trip-header {
    background-color: #f6f1f8;
    display: flex;
    gap: 20px;
    padding: 16px;
  }
  
  @media (max-width: 991px) {
    .trip-header {
      flex-wrap: wrap;
    }
  }
  
  .trip-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 48px;
  }
  
  .trip-details {
    justify-content: space-between;
    align-self: start;
    display: flex;
    gap: 20px;
    flex: 1;
  }
  
  @media (max-width: 991px) {
    .trip-details {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  
  .trip-locations {
    align-self: start;
    display: flex;
    flex-direction: column;
  }
  
  .location-info-trajet {
    display: flex;
    gap: 8px;
    font-size: 16px;
    color: #7f5190;
    font-weight: 500;
    white-space: nowrap;
  }
  
  @media (max-width: 991px) {
    .location-info-trajet {
      white-space: initial;
    }
  }
  
  .location-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
  }
  
  .location-name-trajet {
    font-family: Lexend, sans-serif;
  }
  
  .trip-date {
    color: #b490c0;
    align-self: center;
    margin-top: 8px;
    font: 400 12px Lexend, sans-serif;
  }
  
  .trip-body {
    justify-content: space-between;
    display: flex;
    margin-top: 16px;
    width: 100%;
    gap: 8px;
    font-size: 12px;
    color: #b490c0;
    font-weight: 400;
    padding: 0 16px;
  }
  
  @media (max-width: 991px) {
    .trip-body {
      max-width: 100%;
      flex-wrap: wrap;
      padding-right: 20px;
    }
  }
  
  .trip-info-list {
    display: flex;
    flex-direction: column;
  }
  
  .trip-info-item {
    display: flex;
    gap: 8px;
  }
  
  .info-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
  }
  
  .info-text {
    font-family: Lexend, sans-serif;
    margin: auto 0;
  }
  
  .trip-description {
    display: flex;
    gap: 8px;
    margin: auto 0;
  }
  
  .description-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
    align-self: start;
  }
  
  .description-text {
    font-family: Lexend, sans-serif;
    flex: 1;
  }
  
  .trip-footer {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    font-size: 14px;
    padding: 0 16px;
  }
  
  @media (max-width: 991px) {
    .trip-footer {
      max-width: 100%;
    }
  }
  
  .footer-divider {
    border-radius: 20px;
    background-color: #f6f1f8;
    height: 2px;
  }
  
  @media (max-width: 991px) {
    .footer-divider {
      max-width: 100%;
    }
  }
  
  .footer-content {
    justify-content: space-between;
    display: flex;
    margin-top: 16px;
    width: 100%;
    gap: 20px;
  }
  
  @media (max-width: 991px) {
    .footer-content {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  
  .user-info {
    justify-content: center;
    display: flex;
    gap: 8px;
    font-weight: 600;
  }
  
  .user-avatar {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 44px;
  }
  
  .user-details {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 4px 0;
  }
  
  .user-name {
    color: #7f5190;
    font-family: Lexend, sans-serif;
  }
  
  .user-rating {
    display: flex;
    gap: 4px;
    white-space: nowrap;
  }
  
  @media (max-width: 991px) {
    .user-rating {
      white-space: initial;
    }
  }
  
  .rating-value {
    color: #7f5190;
    font-family: Lexend, sans-serif;
  }
  
  .rating-star {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 14px;
    margin: auto 0;
  }
  
  .rating-count {
    color: #b490c0;
    font-family: Lexend, sans-serif;
  }
  
  .view-trip-button {
    font-family: Lexend, sans-serif;
    justify-content: center;
    border-radius: 15px;
    background-color: #7f5190;
    align-self: start;
    color: #fff;
    font-weight: 400;
    padding: 12px 16px;
  }
  
  @media (max-width: 991px) {
    .view-trip-button {
      padding: 0 20px;
    }
  }
  
  .profile-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 19%;
    margin-left: 20px;
  }
  
  @media (max-width: 991px) {
    .profile-column {
      width: 100%;
    }
  }
  
  .profile-card {
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(180, 144, 192, 0.25);
    background-color: #fff;
    display: flex;
    margin-top: 69px;
    flex-direction: column;
    font-weight: 600;
    width: 100%;
    padding: 16px 16px 32px;
  }
  
  @media (max-width: 991px) {
    .profile-card {
      margin-top: 40px;
    }
  }
  
  .profile-avatar {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 48px;
    box-shadow: 0px 2px 0px 0px rgba(127, 81, 144, 0.25);
  }
  
  .profile-name {
    color: #7f5190;
    margin-top: 16px;
    font: 16px Lexend, sans-serif;
  }
  
  .profile-role {
    color: #b490c0;
    font: 14px Lexend, sans-serif;
  }
  
  .profile-divider {
    align-self: stretch;
    border-radius: 20px;
    background-color: #f6f1f8;
    margin-top: 16px;
    height: 2px;
  }
  
  .profile-info {
    justify-content: center;
    align-items: start;
    align-self: stretch;
    display: flex;
    margin-top: 32px;
    flex-direction: column;
    font-size: 12px;
    color: #302f30;
    padding: 0 16px;
  }
  
  @media (max-width: 991px) {
    .profile-info {
      padding-right: 20px;
    }
  }
  
  .info-item {
    display: flex;
    gap: 16px;
  }
  
  .info-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 32px;
  }
  
  .info-text {
    font-family: Lexend, sans-serif;
    margin: auto 0;
  }

  .interested-btn {
    font-family: Lexend, sans-serif;
    justify-content: center;
    border-radius: 15px;
    background-color: #7f5190;
    color: #fff;
    padding: 12px 16px;
  }

  .view-route-btn {
    font-family: Lexend, sans-serif;
    justify-content: center;
    border-radius: 15px;
    background-color: #f6f1f8;
    color: #7f5190;
    padding: 12px 16px;
    margin-left: 10px;
  }