.profile-card-request {
   /* padding: 16px 16px 0;*/
    padding-bottom: 16px;
    
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
    background-color: #fff;
    display: flex;
    max-width: 735px;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .profile-header-request {
    background-color: #f6f1f8;
    padding-left: 16px;
    padding-top: 16px;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  
  .profile-info-request {
    display: flex;
    gap: 20px;
  }
  
  .user-avatar-request {
    justify-content: center;
    align-self: start;
    display: flex;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }
  
  .avatar-image {
    aspect-ratio: 1;
    object-fit: cover;
    width: 44px;
  }
  
  .user-details {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 4px 0;
  }
  
  .user-name {
    color: #7f5190;
    font-family: Lexend, sans-serif;
  }
  
  .user-rating {
    display: flex;
    gap: 4px;
  }
  
  .rating-value {
    color: #7f5190;
    font-family: Lexend, sans-serif;
  }
  
  .rating-star {
    aspect-ratio: 1;
    object-fit: contain;
    width: 14px;
    margin: auto 0;
  }
  
  .rating-count {
    color: #b490c0;
    font-family: Lexend, sans-serif;
  }
  
  .location-info-request {
    justify-content: space-between;
    display: flex;
    gap: 20px;
    font-size: 16px;
    color: #7f5190;
    font-weight: 500;
    flex: 1;
    padding: 13px 16px;
  }
  
  .location-item-request {
    display: flex;
    gap: 8px;
  }
  
  .location-icon-request {
    aspect-ratio: 1;
    object-fit: contain;
    width: 20px;
  }
  
  .location-name-request {
    font-family: Lexend, sans-serif;
  }
  
  .divider-icon {
    aspect-ratio: 1.16;
    object-fit: contain;
    width: 22px;
    fill: #7f5190;
    stroke-width: 0.2px;
    stroke: #7f5190;
    border: 0 solid rgba(127, 81, 144, 1);
    align-self: start;
  }
  
  .divider {
    border-radius: 20px;
    background-color: #f6f1f8;
    margin-top: 16px;
    height: 2px;
  }
  
  .content-section-request {
    justify-content: space-between;
    margin-top: 16px;
    width: 100%;
    padding: 0 16px;
  }
  
  .content-columns-request {
    gap: 20px;
    display: flex;
  }
  
  .details-column-request {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 0;
  }
  
  .details-list-request {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 12px;
    color: #b490c0;
    font-weight: 400;
  }
  
  .detail-item-request {
    display: flex;
    gap: 8px;
    margin-top: 8px;
  }
  
  .detail-icon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 20px;
  }
  
  .detail-text {
    font-family: Lexend, sans-serif;
    margin: auto 0;
  }
  
  .description-column-request {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
  }
  
  .description-content-request {
    align-self: stretch;
    display: flex;
    gap: 8px;
    font-size: 12px;
    color: #b490c0;
    font-weight: 400;
    margin: auto 0;
  }
  
  .description-icon-request {
    aspect-ratio: 1;
    object-fit: contain;
    width: 20px;
    align-self: start;
  }
  
  .description-text-request {
    font-family: Lexend, sans-serif;
    flex: 1;
  }
  
  .footer-section {
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
    font-weight: 400;
    padding: 0 16px;
  }
  
  .footer-content {
    padding-right: 16px;
    justify-content: space-between;
    display: flex;
    margin-top: 16px;
    width: 100%;
    gap: 16px;
  }
  
  .availability-button {
    border-radius: 15px;
    background-color: #7f5190;
    color: #fff;
    justify-content: center;
    padding: 12px 16px;
    font: 14px Lexend, sans-serif;
  }
  
  .last-seen {
    justify-content: center;
    display: flex;
    gap: 4px;
    font-size: 10px;
    color: #7f5190;
    margin: auto 0;
  }
  
  .last-seen-icon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 16px;
  }
  
  .last-seen-text {
    font-family: Lexend, sans-serif;
  }
  
  @media (max-width: 991px) {
    .profile-header-request,
    .divider,
    .content-section,
    .footer-section {
      max-width: 100%;
    }
  
    .profile-info-request,
    .location-info-request {
      flex-wrap: wrap;
    }
  
    .user-avatar-request,
    .user-details-request,
    .user-rating-request {
      white-space: initial;
    }
  
    .content-section {
      padding-right: 20px;
    }
  
    .content-columns {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
  
    .details-column,
    .description-column {
      width: 100%;
      margin-left: 0;
      margin-top: 40px;
    }
  
    .footer-content {
      flex-wrap: wrap;
    }
  }