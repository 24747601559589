.contact-modal {
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
    background-color: #fff;
    display: flex;
    max-width: 591px;
    flex-direction: column;
    font-weight: 400;
    padding: 16px;
  }
  
  .modal-title {
    color: #7f5190;
    align-self: flex-start;
    font: 500 20px Lexend, sans-serif;
  }
  
  .modal-description {
    color: #7f5190;
    align-self: flex-start;
    margin-top: 32px;
    font: 14px Lexend, sans-serif;
  }
  
  .modal-divider {
    border-radius: 20px;
    background-color: #f6f1f8;
    margin-top: 32px;
    height: 2px;
  }
  
  .message-label {
    color: #7f5190;
    margin-top: 32px;
    font: 500 16px Lexend, sans-serif;
  }
  
  .message-input {
    align-items: flex-start;
    border-radius: 10px;
    background-color: #f6f1f8;
    margin-top: 16px;
    color: #cbadd6;
    justify-content: center;
    padding: 10px 10px 48px;
    font: 12px Lexend, sans-serif;
  }
  
  .button-container {
    display: flex;
    margin-top: 16px;
    gap: 16px;
    font-size: 16px;
  }
  
  .cancel-button {
    font-family: Lexend, sans-serif;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #f6f1f8;
    color: #7f5190;
    white-space: nowrap;
    flex: 1;
    padding: 12px 16px;
  }
  
  .send-button {
    font-family: Lexend, sans-serif;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #7f5190;
    color: #f6f1f8;
    flex: 1;
    padding: 12px 16px;
  }
  
  @media (max-width: 991px) {
    .modal-title,
    .modal-description,
    .modal-divider,
    .message-label,
    .message-input,
    .button-container {
      max-width: 100%;
    }
  
    .message-input {
      padding-right: 20px;
    }
  
    .button-container {
      flex-wrap: wrap;
    }
  
    .cancel-button {
      white-space: initial;
      padding: 0 20px;
    }
  
    .send-button {
      padding: 0 20px;
    }
  }

  .reponse-modal-parent{

    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5000;


  }


  .modal-reponse-not-connected{

    width: 50%;
    height: 100px;
    justify-content: center;
    background-color: #fff;
    align-items: center;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
  }


  .contact-form-reponse {
    background-color: #fff;
    display: flex;
    max-width: 801px;
    width: 100%;
    flex-direction: column;
    padding: 16px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(127, 81, 144, 0.25);
  }