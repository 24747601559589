body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex; /* Use flexbox */
  justify-content: center; /* Align items horizontally */
  margin: 30px;
  gap: 5px;

}

.autocomplete{

  
}
@media (min-width: 768px) {
  .search-zone-home{
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    width: 90%;
    height: 70px;
    margin: auto;
    justify-content: center;
    gap: 10px;
    
  
  }
}

@media (max-width: 760px) {
  .search-zone-home{
    display: grid;
    grid-template-columns: 90%;
    margin-left: 30px;
    margin-right: 30px;
    gap: 10px;
  
  }
}
