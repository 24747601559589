

@media (min-width: 768px) {
    .content-zone{
        display: grid;
        grid-template-columns: 100%;
    }

    .react-tabs {
        display: flex;
    }
      
    .react-tabs__tab-list {
        flex-direction: column; /* Align tabs vertically */
        margin-right: 20px; /* Add some space between tabs and content */
        border-bottom: none !important; /* Removes the bottom border from the tabs */
    }
      
    .react-tabs__tab-panel {
        width: 100%;
    }
    .react-tabs__tab{
        display: block !important;
    }
}

@media (max-width: 768px) {
    .content-zone{
        display: grid;
        grid-template-columns: 100%;
    }
}


.react-tabs__tab{
    color: var(--primary-color);
}

.react-tabs__tab--selected{
    color: #ffff;
    background-color: var(--primary-color);
}

.form-compte{
    margin-left: 10px;
}

.compte-label{
    width: 150px;
}

#button-zone-trajets{
    display: block;
    width: 100%;
    justify-content: left;
}

#panel-trajets{
    display: block;
}

.icon-button:hover{
    opacity: 0.7;
    cursor: pointer;
}

.form-commentaire{
    min-height: 200px;
}

.align-text-right{
    text-align: right;
    padding-right: 10px;
}
/*Mon Trajet*/

.user-trajet{
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    min-height: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    
}

#side{
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    width: 100%;
    background-color: ghostwhite;
    justify-content: center;
    color: var(--primary-color);
    padding: 5px;
}

#right{

    padding: 5px;
    display: grid;
    grid-template-columns: 6fr 1fr;
}

#actions{
    display: flex;
    width: 50%;

}
#actions li{
    display: inline;
    margin-right: 10px;
}



/* my Reservation */

.my-reservation{

    display: grid;
    grid-template-columns: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin-bottom: 10px;
    
}
.my-reservation-header{
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    color: var(--primary-color);
    background-color: ghostwhite;
    padding-top: 10px;
    padding-left: 10px;

}
.my-reservation-body{
    padding-top: 10px;
    padding-left: 10px;


}

.my-reservation-actions{
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    gap: 10px;
    margin-bottom: 10px;
}

/* Modal actions */

.modal-actions{
    display: flex;
    gap: 10px;
}

/* demande item */

.demande-item{

    display: grid;
    grid-template-columns: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin-bottom: 10px;
    
}
.demande-item-header{
    display: grid;
    grid-template-columns: 4fr 4fr 1fr;
    color: var(--primary-color);
    background-color: ghostwhite;
    padding-top: 10px;
    padding-left: 10px;

}
.demande-item-body{
    padding-left: 10px;

}

.demande-item-actions{
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    gap: 10px;
    margin-bottom: 10px;
}


/* custom trajet */

#user-trajet-zone{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

}

#zone-reservation{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

}

@media (max-width: 768px) {
    #user-trajet-zone{
        display: grid;
        width: 100%;
        grid-template-columns: 100%;
        gap: 10px;
    
    }

    #zone-reservation{
        display: grid;
        width: 100%;
        grid-template-columns: 100%;
        gap: 10px;
    
    }
}

.chip-trajet{
    margin-right: 10px;
}

.main-content-compte{
    min-height: 600px;
}

/*ABonnement*/

.abonnement-list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
}
.abonnement-item{
    
    width: 100%;
    gap: 10px;
    border-bottom: 1px solid var(--primary-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    min-height: 100px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.abonnement-item-header{
    display: flex;
    height: 50px;
    grid-template-columns: 1fr 1fr;
    color: var(--primary-color);
    justify-content: space-between;
    width: 100%;
    padding-right: 10px;
    
}

.abonnement-item-footer{
    display: flex;
    height: 50px;
    grid-template-columns: 1fr 1fr;
    color: var(--primary-color);
    justify-content: space-between;
    width: 100%;
    padding-right: 10px;
}


.facture-list{
    padding: 5px;
    border-bottom: 1px solid var(--primary-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 10px;

}

