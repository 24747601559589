#checkmark-div{
    border-radius:200px; 
    height:200px;
     width:200px; 
     background: #F8FAF5; 
     margin: auto;

}


.msg-success {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
  }
.details-message {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size:20px;
    margin: 0;
  }
.checkmark {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left: 50px;
}
.card-success {
  background: white;
  padding: 60px;
  display: inline-block;
  margin: auto;
}

.img-felicitations{
    width: 30%;
    height: 30%;
}

.confirmation-msg{
    text-align: center;
    margin-top: 50px;
    color: #9ABC66;
}