#stats-trajet{
    display: grid;
    width: 100%;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    #stats-trajet{
        display: grid;
        width: 100%;
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: 10px;
    
    }
}

.stats-trajet-item{

    /*background-color: #f9f9f9;*/
    background-color: var(--3-Secondair, #f6f1f8);
    justify-content: center;
    padding-top: 20px;
    text-align: center;
    height: 120px;
    border-radius: 10px;

}

.texte-stat{
    font-size: 15px;
}


.majtrajet{
    display: grid;
    grid-template-columns: 3fr 7fr;
    min-height:  100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 40px;
}

.majtrajet-left{
    display: block;
    flex-direction: row;
    background-color: #f9f9f9;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.majtrajet-right{
   padding: 20px;
   text-align: center;
   margin: auto;
   text-justify: auto;
}

.main-section{

    min-height: 600px;
}
