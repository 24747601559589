.container-edit{

    width: 50%;
    margin: auto;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .container-search{
        width: 60%;
        display: grid;
        grid-template-columns: 2fr 2fr 2fr;
        margin: auto;
        justify-content: center;
    }

    .main-content-zone{

        display: grid;
        grid-template-columns: 2fr 7fr;
        width: 80%;
        justify-content: center;
        gap:  20px;
        margin: auto;
        max-width: 1024px;
    
    }
    
}

@media (min-width: 1440px) {
    
    .main-content-zone{

        display: grid;
        grid-template-columns: 2fr 7fr;
        width: 80%;
        justify-content: center;
        gap:  20px;
        margin: auto;
        max-width: 1024px;
    
    }
    
}

@media (min-width: 2559px) {
    
    .main-content-zone{

        display: grid;
        grid-template-columns: 2fr 7fr;
        width: 45%;
        justify-content: center;
        gap:  20px;
        margin: auto;
        max-width: 1024px;
    
    }
    
}


@media (max-width: 768px) {
    .main-content-zone{

        display: grid;
        grid-template-columns: 100%;
        width: 90%;
        justify-content: center;
        margin: auto;
        margin-left: 20px;
    
    }
}





.sidebar-zone{
    display: grid;
    grid-template-columns: 100%;
    flex-direction: column;
    height: 200px;
    justify-content: center;
    text-align: center;
}

.sidebar-zone button{
    width: 100%;
}