.plan-abonnement{

    border-radius: 20px;
    box-shadow: 4px 4px 4px 4px rgba(127, 81, 144, 0.25);
}



@media (min-width: 999px) {
    .pricing-container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
    }
}

@media (max-width: 999px) {
    .pricing-container{
        display: grid;
        grid-template-columns: 100%;
        gap: 30px;
    }
}



.titre-formule{
    color: #7F5190;
    font-size: 25px;
}

.prix-formule{
    /*color: #A77CB6;*/
    color: #A77CB6;
}

.plan-type{
    width: 200px;
    justify-content: center;
    margin: auto;
}

.conditions-abonnement{

    padding: 10px;
}

.details-abonnement{
    height: 400px;
}

.formule-feature{
    margin-top: 10px;
}

.description-formule{
    height: 150px;
}

.image-plan{
    width: 50%;
    height: auto;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 20px;
}

.titre-condition{
    color: #7F5190;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}



@media (max-width: 990px) {
    .top-description{
        width: 90%;
        margin: auto;
        margin-top: 10px;
    }
}

@media (min-width: 990px) {
    .top-description{
        width: 70%;
        margin: auto;
        margin-top: 10px;
    }
}